@import 'colors';
@import 'breadcrumb';

$line-height-computed: 1em;

// PRECISE CONTROL OVER RESPONSIVE TYPOGRAPHY FOR SASS
//  ---------------------------------------------------
//  Indrek Paas @indrekpaas
//
//  Inspired by Mike Riethmuller's Precise control over responsive typography
//  http://madebymike.com.au/writing/precise-control-responsive-typography/

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

a {
  color: $background-color;
}

.space-for-nav {
    padding-top: 80px;
    @media (max-width: 768px ) { padding-top: 50px; }
}

.btn.btn-primary.btn-back {
  background-color: $background-color;
  border-color: $border-color;

  &:hover, &:active {
    background-color: $background-color;
    border-color: $border-color;
  }
}

// ------------------------
// customize Bootstrap menu: increase menu height to 80px
// ------------------------

.menu-top {
  padding: 10px 0;

  a {
    padding: 0 15px;
    color: rgb(2, 68, 118);
  }

  .dropdown-menu {
    border-radius: 0;
    background-color: $line-color;

    li {
      padding: 10px 0;

      a {
        padding: 0;
        display: inline-block;
        width: 100%;
      }
    }

    .dropdown-divider {
      border-top: 2px solid rgba(0, 0, 0, 0.15);
      margin: 0;
    }
  }

  &:hover {
    background-color: $line-color;

    a {
      color: #fff;
      text-decoration: none;
    }

    ul {

      li:hover {
        background-color: $background-color;
      }

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.dropdown-menu {
    background: #eee;
    margin: 0;
    padding: 0;
}
.dropdown:hover > .dropdown-menu {
    display: block;
}

// this style was removed from bootstrap4
blockquote {
    padding: 10px 20px;
    border-left: 5px solid #eeeeee;
}

.top-bar {
    max-width: 100%;
    height: 92px;
    background-color: rgb(2, 68, 118);
}

.inner-top-bar {
    max-width: 1280px;
    margin: auto;
    height: 100%;
    display: flex;
}

.inst-logo {
    height: 72px;
    line-height: 72px;
    display: inline-block;
    margin-right: 50px;
    align-self: center;

    img {
        vertical-align: middle;
        width: 72px;
        height: auto;
        display: flex;
    }
}

.inst-title {
    font-family: 'Trajan Pro Bold';
    font-size: 28px;
    color: #fff;
    align-self: center;

    @media(max-width: 768px) {
      font-size: 18px;
    }
}

.search-bar {
    display: flex;
    align-self: center;
    margin-right: 0;
    margin-left: auto;
}

.search-form,
.search-btn {
    height: 48px;
    display: flex;
    align-self: center;

    @media(max-width: 768px) {
      max-width: 100px;
      width: 100%;

      i {
        margin: 0 auto;
      }
    }
}

.search-form input {
    margin: 0;
    padding: 0;
    height: 48px;
    max-width: 170px;
    border-radius: 0;

    @media(max-width: 768px) {
      max-width: 100px;
      height: 30px;
    }
}

.search-btn {
    font-size: 39px;
    background-color: $line-color;
    color: #fff;
    border-radius: 0;

    @media(max-width: 768px) {
      font-size: 15px;
      height: 30px;
    }
}

.site-header {
    height: 82px;
    max-width: 1280px;
    margin: auto;
    margin-bottom: 10px;
    border-top: 6px solid $line-color;
    border-bottom: 6px solid $line-color;
}

.site-title {
    height: 50px;
    line-height: 50px;
    margin: 10px 0;
    color: rgb(2, 68, 118);
    font-family: 'Trebuchet MS';
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    border-right: 4px solid $line-color;
    border-left: 4px solid $line-color;
    display: flex;
    justify-content: center;

    .site-logo-title {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-left: 35px;

      img {
        margin-right: 25px;
        height: 48px;
      }
    }

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 25px;
      padding-right: 5px;

      .site-logo-title {
        margin-left: 0;
      }

      img {
        margin-right: 20px;
        margin-left: 5px;
      }

      p {
        margin: 0;
        align-self: center;
      }
    }
}

.site-navigation {
    padding: 0;
    max-width: 1280px;
    margin: auto;
}

.site-menu {
    width: 100%;
    justify-content: space-between;
    font-size: 16px;
}

.site-content {
    max-width: 1280px;
    padding: 5px;
    margin: auto;
}

.navbar {
    background-color: #fff;
    border: 0;
    margin-bottom: 0;
    border-bottom-color: #e9e9e9;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        height: 50px;
        z-index: 1000;

        .menu-top {
          background-color: #fff;

          .dropdown-divider {
            display: none;
          }

          &:hover {
            a {
              color: $line-color;
            }
            ul {
              background-color: #fff;
              border: none;

              a {
                color: $line-color;
                padding-left: 20px;
              }
            }
          }
        }
    }
}

.navbar-light {
  .navbar-nav {
    .nav-item {
      padding-top: 0;
      padding-bottom: 0;

      &.active {
        border-bottom: 2px solid #005ea4;
      }

      .nav-link {
        color: rgb(2, 68, 118);
        padding-top: 9.5px !important;
        padding-bottom: 9.5px !important;
        // font-family: 'Trebuchet MS';
        font-size: 16px;


        &:hover {
          @media (min-width: 769px) {
            color: #fff;
          }
        }
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      .nav-item.active {
        background-color:#007bd7;
        border: none;
      }
      .nav-link {
        @media (min-width: 769px) {
          color: #fff;
        }
      }
    }
  }
}

.navbar-brand {
  height: 80px;
  line-height: 80px;
  padding: 3px 15px;

  img {
    width: 74px;
    height: auto;
  }

  @media (max-width: 768px) {
      height: auto;
      padding: 3px 10px;

      img {
        width: 44px;
        height: auto;
      }
  }
}


.navbar-toggle {
  margin-top: 23px;
  padding: 9px 10px !important;

  .icon-bar {
    background-color: #808080;
    height: 4px;
    width: 30px;
  }
  @media (max-width: 768px) {
      margin: 0;
      height: auto;
      padding: 15px 10px!important;
      border: none;
  }
}

.menu-item {

  a {
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    background: transparent;
    transition: background .3s, color .3s;
  }

  a:hover,
  a:focus,
  a:active {
    background-color: #158cba;
    color: white;
  }

}

.menu-group {
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}

.list-unstyled {
  border-left: 2px dotted rgba(0, 0, 0, .075);

  >.menu-item:last-child {
    margin-bottom: 10.5px;
  }
}

.breadcrumb {
  // font-size: .8em;
  margin: 1em 0 2em 0;

  > li + li:before {
    color: #777;
    content: '\276F\00a0';
    padding: 0 5px;
  }
}

@media (max-width: 768px) {
  .navbar-collapse {
      box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.2);
      background-color: #f5f5f5;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 26.5px;
    padding-bottom: 26.5px;
    line-height: 27px;
    background: transparent;
    border-bottom: 3px solid transparent;
    transition: background .5s, border-bottom .7s;
  }
}

// end customize Bootstrap menu


// ------------------------
//  overlay for redirecting hu to ro
// ------------------------

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #fff;
  background: rgba(0, 0, 0, .9);
  overflow-x: hidden;

  p {
    color: lightgray;
  }
}

.show-overlay {
  width: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

// end overlay



// ------------------------
//  language chooser flags
// ------------------------

.site-lang-chooser {
  padding: 0;
  width: 30px;
  margin-right: 5px;
  list-style-type: none;

  .lang {
    line-height: 20px;

    a {
      color: gray;
      padding: 5px;
      font-size: initial;
      font-weight: normal;
      text-decoration: none;
      text-transform: uppercase;
    }
    a:hover {
      background-color: $line-color;
      color: white;
    }

  }
  .lang.active {
    a {
      color: #2780e3;
    }
  }

  @media (max-width: 768px) {
    margin: 0;

    .lang {
      a {
        padding: 2px;
      }
    }
  }
}

// end language chooser flags



.affix { top: 130px; }

.fa-map-marker:hover,
.fa-map-marker:active,
.fa-map-marker:focus {
  color: red;
}

.panel-height {
  height: 150px;
}

// manual configuration for the spacer plugin in:
// https://github.com/aldryn/aldryn-bootstrap3/
// requires bootstrap 3

.spacer {
  margin-bottom: $line-height-computed;
  margin-top: $line-height-computed;
}

.spacer-xs {
  margin-bottom: $line-height-computed / 2;
  margin-top: $line-height-computed / 2;
}

.spacer-sm {
  margin-bottom: $line-height-computed / 1.5;
  margin-top: $line-height-computed / 1.5;
}

.spacer-md {
  @extend .spacer;
}

.spacer-lg {
  margin-bottom: $line-height-computed * 2;
  margin-top: $line-height-computed * 2;
}

.spacer-zero {
  margin: 0;
}

.spacer:after,
.spacer-xs:after,
.spacer-sm:after,
.spacer-md:after,
.spacer-lg:after,
.spacer-zero:after {
  clear: both;
  content: '';
  display: table;
}

// end spacer plugin


// customize Bootstrap styles
.tab-content {
  border-right: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: 2em 1em 1em 1em;
}

.nav-tabs .active { font-weight: bold; }

.carousel-caption > h1,
.carousel-caption > h2,
.carousel-caption > h3,
.carousel-caption > h4,
.carousel-caption > h5,
.carousel-caption > h6 {
  text-shadow: 3px 3px 4px #333;
  color: #f5f5f5;
}

.img-simple {
    margin: 0 0 30px 0;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 5px;
}

// add Fontawesome plus/minus icon to accordion
.panel-title a {
  display: block;
  // padding: 10px 15px;
}

.panel-title > a:before {
  content:"\f056";
  float: right !important;
  font-family: "Font Awesome 5 Free";
  padding: 0 5px;
}

.panel-title > a.collapsed:before {
    float: right !important;
    content:"\f055";
}
.panel-title > a:hover,
.panel-title > a:active,
.panel-title > a:focus  {
    text-decoration:none;
}

// END: add Fontawesome plus/minus icon to accordion

// file dowload link
.download-link {
  padding: 5px 10px;

  &:hover {
    text-decoration: none;
    background: aliceblue;
  }
}

// downloadable file size (KB) label
span.label.label-default {
    background-color: #ddd;
    color: $background-color;
    vertical-align: baseline;
    padding: .1em .4em .1em;
    border-radius: .25em;
}


// contact address formatting

.contact-page {
  div[data-toggle="collapse"] {
    .card-header {
      border-left: 3px solid $line-color;
      border-radius: 5px;
      color: #158cba;
      background-color: aliceblue;

      h5 {
        margin-bottom: 0;
      }
    }
  }
}

// tabel contained in Aldryn People's description field
.table-schedule {
    background-color: rgba(245,245,245,0.6);

    .table-schedule {
        th {
            font-weight: 300;
            color: $base-color;
            border-bottom: 1px solid #ddd;
        }

        tr:hover { background-color: #ddd; }

        td {
            font-weight: 400;
            border-bottom: 1px solid #ddd;
        }
    }
}

// featured style: practycally is a toggler element
.person-item-toggler {
    margin: 1em 0;
    border-left: 3px solid #158cba;
    border-radius: 5px;

    &[open] { border-left: 0 solid lightgray; }

    summary {
        background: aliceblue;
        padding: 0px 15px;
        color: #158cba;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    summary::focus { outline: none; }

    h4 {
        display: inline-block;
        font-weight: 300;
        width: 90%;
    }

    .contact { padding: 1em; }

    li {
      list-style: none;
      padding: 5px;
      position: relative;
      text-align: left;
      vertical-align: middle;
      width: 100%;

      span {
        display: block;
        float: left;
      }

      .fa-rounded {
        margin-right: 10px;
        background-color: #158cba;
        border-radius: 50%;
        color: #f5f5f5;
        height: 27px;
        padding: 5px;
        text-align: center;
        width: 27px;
      }
    }
}


.person-item {
    margin: 23px 0;
    border-radius: 5px;

    h4 {
        font-weight: 300;
        margin: 0;
        padding: 10px;
        background: aliceblue;
        border-bottom: 1px solid #eeeeee;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .contact {
        padding: 0;
        margin: 20px 0;

        strong { font-weight: 300; }
        .description { margin-top: 15px; }
    }

    li {
      list-style: none;
      padding: 5px;
      position: relative;
      text-align: left;
      vertical-align: middle;
      width: 100%;

      span {
        display: block;
        float: left;
      }

      .fa-rounded {
        margin-right: 10px;
        background-color: #158cba;
        border-radius: 50%;
        color: #f5f5f5;
        height: 27px;
        padding: 5px;
        text-align: center;
        width: 27px;
      }
    }
    &.aligned-vertcally { margin: 56px 0 0 0; }
}

.compact { margin-top: 0; }

hr {
  background-image: linear-gradient(to right, transparent, #dbdbdb, transparent);
  border: 0;
  height: 1px;
  margin: 3px 0;
}

hr.thin { margin: 22px 0; }
hr.thick { margin: 33px 0; }
hr.taller { margin: 66px 0; }


.divider {
    border: 0;
    height: 1px;
    margin: 66px auto;
    background-image: -webkit-linear-gradient(left, transparent, #999, transparent);
    background-image: linear-gradient(to right, transparent, #999, transparent);
    text-align: center;
    position: relative;
    clear: both;

    .fa {
        background: #fff;
        border: 2px solid #999;
        border-radius: 50px;
        color: #d2ac67;
        display: inline-block;
        font-size: 15px;
        height: 30px;
        left: 50%;
        line-height: 30px;
        margin-left: -16px;
        position: absolute;
        text-align: center;
        top: -15px;
        width: 30px;
        z-index: 1;
    }

    .fa:after {
        border-radius: 50%;
        border: 3px solid #ddd;
        box-sizing: content-box;
        content: "";
        display: block;
        height: 100%;
        left: -4px;
        padding: 1px;
        position: absolute;
        top: -4px;
        width: 100%;
    }
}

.page-title {
    border-bottom: 2px solid rgb(238, 238, 238);
    margin-bottom: 2em;
}

.map-canvas {
    height: 60vh;
    width: 100%;

    #iw-container .iw-title {
        background-color: #158cba;
        color: white;
        font-family: 'Open Sans Condensed', sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
    }

    #iw-container .iw-content {
        // display: flex;
        font-size: 16px;
        font-weight: 400;
        justify-content: space-between;
        overflow: auto;
        padding: 10px;
    }

    .gm-style-iw {
        background-color: #fff;
        border: 1px solid rgba(72, 181, 233, 0.6);
        box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
        left: 0 !important;
        top: 15px !important; // move the infowindow 15px down
        width: 350px !important;
    }

    .iw-img {
        min-height: 200px;
        min-width: 300px;
    }

    p {
        font-size: 0.8em;
    }

}

.nav-tabs {

    > li {

      a { background: #f5f5f5; }
      a:hover { border-top: 3px solid $line-color !important; }

      &.active {

        > a {
            // background: #F5F5F5;
            border-top: 3px solid $line-color !important;
            box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.4), 0px -3px 5px -2px rgba(0, 0, 0, 0.4);
          }
      }
    }
}


// category page fomatting

.category {
  padding: 15px 0;
}



.section-container {
    padding: 10px;
    overflow: hidden;
    border-radius: 5px;

    .section-item {
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .section-item:hover {
        cursor: pointer;
        border: 1px solid $line-color;
        // box-shadow: 0px 0px 5px 1px #cccccc;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .section-item-img {
        img {
            width: 100%;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom: 1px solid #ccc;
        }
    }

    .section-item-info {
        position: absolute;
        display: none;
        bottom: 0;
        left: 0;
        background-color: rgba(255,255,255,1);
        height: calc(100% - 22px);
        width: calc(100% - 22px);
        padding: 15px;
        text-align: center;
        margin-left: 11px;
        margin-bottom: 11px;
        border-radius: 5px;
    }

    .item-title {
        height: 3.5em;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        color: $background-color;
    }

    .item-link {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        width: 10em;
        margin: 0 auto;
        display: block;
        background-color: $background-color;
        border-color: $border-color;
    }
}

.search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}



// Blog --> Category
.category-title {
    font-weight: 400;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
    color: $background-color;
}

// blog category plugin formatting
.category-item {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    a {
        transition: background 0.1s;
        border-bottom: 1px solid #EDEDDE;
        padding: 8px 0 8px 18px;
        display: block;
        color: #666;
        text-decoration: none;
        line-height: 20px;
    }

    a:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid $background-color;
        margin: 7px 0 0 -9px;
        position: absolute;
    }

    a:hover {
        background-color: whitesmoke;
        text-decoration: none;
        color: #0088cc;
    }

    a.selected { font-weight: bold; }

}

.post-date {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    float: left;
    margin: 0 20px 20px 0;
    // margin: 10px 20px 20px 0;
    text-align: center;

    .day {
      color: #0088CC;
      background: #FFF;
      border-radius: 2px 2px 0 0;
      display: block;
      font-size: 16px;
      padding: 10px;
    }

    .month {
      background-color: #0088CC;
      border-radius: 0 0 2px 2px;
      box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07) inset;
      color: #FFF;
      font-size: 0.9em;
      padding: 0 10px 2px;
      display: inline-block;
      width: 100%;
    }
}

.img-article {
  margin-right: 20px;
  margin-bottom: 1em;
  float: left;
}
@media (max-width: 768px) { .img-article { margin-bottom: 2em; } }

.blog-divider {
  border: 0;
  height: 1px;
  margin: 40px auto;
  background: #005ea4;
  text-align: center;
  position: relative;
  clear: both;
}

.img-article-small {
    float: left;
    margin: 5px 20px 0 0;
}

.page-description {
      color: #d2ac67;
      margin: 1em 0 2em 0;
      font-size: 20px;
      font-weight: 200;

      @media (max-width: 768px) {
        margin: 0 0 16px 0;
      }
}


div[aria-expanded="false"] .card-header > div::before,
div[aria-expanded="false"] .card-header > p::before {
  font-family: "Font Awesome 5 Free";
  float: right !important;
  content:"\f055";
  font-weight: 900;
}

div[aria-expanded="true"] .card-header > div::before,
div[aria-expanded="true"] .card-header > p::before {
  font-family: "Font Awesome 5 Free";
  float: right !important;
  content: "\f056";
  font-weight: 900;
}


div[data-toggle="collapse"] {
  margin-bottom: 5px;
}

.card {
    border-radius: 5px;
}

.card-header {
  p {
    margin-bottom: 0;
  }
}

.card-body {
  background-color: #fff;

  p {
    color: #000;

    i {
      margin-right: 10px;

      &:not(.fa-download) {
        background-color: $background-color;
        border-radius: 50%;
        color: #f5f5f5;
        height: 27px;
        padding: 5px;
        text-align: center;
        width: 27px;
      }
    }
  }

  a {
    color: $background-color;
  }

}

.card-sidebar {
  border-top: 1px solid $line-color;
  border-bottom: 1px solid $line-color;
  padding: 15px;
}

.blog-latest-entries {
  .article {
    border: none;
    &:first-of-type { border-top: 1px solid $line-color; }
    &:last-of-type { border-bottom: 1px solid $line-color; }
  }
}

.article {
    padding: 10px 15px;
    margin: 0 0 20px 0;
    border-radius: 5px;
    background: rgba(245, 245, 245, 1);
    border-bottom: 4px solid #f5f5f5;
    overflow: auto;
    border-top: 1px solid $line-color;
    border-bottom: 1px solid $line-color;

    .item-meta {
        margin: 15px 0;
    }

    .categories {
        margin: -14px 0 20px -16px;

        a.label {
            border-bottom-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .article-title {
      font-size: 18px;
    }

    .blog-lead {
      font-size: 16px;
    }

    h4 a {
      color: $background-color;
    }
}

.read-more {
    .btn-info {
        background-color: $background-color;
        border-color: $border-color;
    }
}


.plugin { margin: 0 0 2em 0; }


// page-header formatting
.page-header {
    // background-color: #555555;
    // border-bottom: 5px solid #ddd;
    // border-top: 5px solid #158cba;
    margin: 0 auto;
    margin-bottom: 60px;
    // min-height: 50px;
    max-width: 1280px;
    // padding: 20px 0;
    // position: relative;
    // text-align: left;

    h2 {
        // margin: 0;
        border-bottom: 2px solid $line-color;
        // color: #fff;
        // display: inline-block;
        font-size: 32px;
        font-weight: 200;
        // line-height: 32px;
        // margin: 0 0 -25px;
        padding: 0 0 25px;
        // position: relative;
    }

    // .breadcrumb {
    //     border: none;
    //     background-color: #555;
    //     margin: 0;
    //     padding: 0;
    // }

    a { color: $background-color; }
    .active, span { color: #999; }

    @media (max-width: 768px) {
        margin: 0 0 16px 0;
    }
}

.activity {
    h4 {}

    ul { padding: 0; }

    li {
        list-style-type: none;
        margin: 0 0 13px 0;
    }

    li:before {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        color: $line-color;
        font-weight: 900;
        padding: 5px 5px 5px 0;
    }
}

.activity-1 {
    @extend .activity;
    h3, h4 { color: $line-color; }
    li:before { color: $line-color; }
}

.activity-2 {
    @extend .activity;
    h3, h4 { color: $secondary-color; }
    li:before { color: $secondary-color; }
}

.activity-3 {
    @extend .activity;
    h3, h4 { color: $tertiary-color; }
    li:before { color: $tertiary-color; }
}

.activity-4 {
    @extend .activity;
    h3, h4 { color: $base-color; }
    li:before { color: $base-color; }
}

// indented
.activity-1-indented {
    @extend .activity;
    h3, h4 { color: $line-color; }
    li:before { color: $line-color; }
    li { margin-left: 1.5em; }
}

.activity-2-indented {
    @extend .activity;
    h3, h4 { color: $secondary-color; }
    li:before { color: $secondary-color; }
    li { margin-left: 1.5em; }
}

.activity-3-indented {
    @extend .activity;
    h3, h4 { color: $tertiary-color; }
    li:before { color: $tertiary-color; }
    li { margin-left: 1.5em; }
}

.activity-4-indented {
    @extend .activity;
    h3, h4 { color: $base-color; }
    li:before { color: $base-color; }
}

.footer {
    background-color: $base-color;
    border-top: 4px solid #dddddd;
    color: white;
    position: relative;
    font-weight: 200;

    hr { background-image: linear-gradient(to right, transparent, #666, transparent); }
    .glyphicon { color: $base-color; }

    .btn {
      i {
        padding-right: 5px;
      }
    }

    .btn-lg {
        font-size: 22px;
        font-weight: 400;
        background-color: $background-color;
        border-color: $border-color;
    }

    .btn-sm {
        font-size: 16px;
        font-weight: 400;
    }

    h3, h4 {
        border-bottom: 2px groove rgba(119, 119, 119, .2);
        color: #999;
        font-weight: 200;
    }
    blockquote {
        border-left-color: $border-color;
        margin-left: 20px;
        font-weight: 200;
        font-size: 16px;
    }
}

.jump-top > a {
  color: $background-color;
  background: #ddd;
  border: 6px solid #ddd;
  border-radius: 50%;
  display: block;
  font-size: 30px;
  height: 106px;
  left: 50%;
  line-height: 1;
  margin: 0 0 0 -53px;
  position: relative;
  text-align: center;
  top: 62px;
  width: 106px;

  &:hover {
    color: #75caeb;
  }
}

figure {
  img {
    width: 100%;
    height: auto;
  }
}

video {
    width: 100%;
}

// style for pagination
.page-item.active .page-link {
  background-color: $background-color;
  border-color: $border-color;
}

.app {
  .carousel {
    width: 80%;
    margin: 0 auto;
  }
}

// style for jumbotron paragraphs
.jumbotron p {
  font-size: 21px;
}

.file-list-item {
  border: 1px solid lightgray;
  border-left: 3px solid #007bd7;
}

// style for django-el-pagination
.el-pagination {
  display: flex;
  padding-left: 0;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;

  & .endless_page_current, .endless_page_link {
    padding: 10px 15px;
    text-decoration: none;
    border: 1px solid transparent;
    background-color: #f0f0f0;

    &:hover, &:focus {
      background-color: #007bd7;
    }
  }

  & .endless_page_current {
    background-color: #007bd7;
    border-color: #006ec1;
  }

  & span:first-child, a:first-child {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  & span:last-child, a:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

// highlight search term inside result set
.highlighted {
  font-weight: bold;
}
