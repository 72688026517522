/* Breadcrumbs from http://bootsnipp.com/snippets/featured/triangle-breadcrumbs-arrows */

#breadcrumb {
  margin: 25px 0;
}

.btn-bc {
  color: $primary-color;
  background-color: whitesmoke;
  // border-color: #ccc;
  font-weight: normal;
  text-transform: none;
  border: 1px solid #e7e7e7;
}

.btn-bc:hover,
.btn-bc:active,
.btn-bc:focus {
  color: $primary-color;
  background-color: #ddd;
  border-bottom: 1px solid #ddd;
  // border-color: #999;  
  box-shadow: none;
  margin-top: 0; // overwrites theme default
}

.btn-bc.inactive {
  color: #555;
}

.btn-bc.inactive:hover,
.btn-bc.inactive:active,
.btn-bc.inactive:focus {
  cursor: default;
}

.btn-breadcrumb .btn:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 10px solid whitesmoke;// white;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: 100%;
    z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 10px solid rgb(173, 173, 173);
    position: absolute;
    top: 50%;
    margin-top: -17px;
    margin-left: 1px;
    left: 100%;
    z-index: 3;
}

.btn-breadcrumb .btn {
    padding: 6px 12px 6px 24px;
}

.btn-breadcrumb .btn:first-child {
    padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
    padding: 6px 18px 6px 24px;
}


/** btn-bc button **/

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
    border-left: 10px solid #f5f5f5;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
    border-left: 10px solid #ccc;
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after,
.btn-breadcrumb .btn.btn-bc:focus:not(:last-child):after,
.btn-breadcrumb .btn.btn-bc:active:not(:last-child):after {
    border-left: 10px solid #ddd;
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
    border-left: 10px solid #ddd;
}


/* The responsive part */

.btn-breadcrumb>*>div {
    /* With less: .text-overflow(); */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-breadcrumb>*:nth-child(n+2) {
    display: none;
}


/* === For phones =================================== */

@media (max-width: 767px) {
    .btn-breadcrumb>*:nth-last-child(-n+2) {
        display: block;
    }
    .btn-breadcrumb>* div {
        max-width: 60px;
    }
}


/* === For tablets ================================== */

@media (min-width: 768px) and (max-width:991px) {
    .btn-breadcrumb>*:nth-last-child(-n+4) {
        display: block;
    }
    .btn-breadcrumb>* div {
        max-width: 100px;
    }
}


/* === For desktops ================================== */

@media (min-width: 992px) {
    .btn-breadcrumb>*:nth-last-child(-n+6) {
        display: block;
    }
    .btn-breadcrumb>* div {
        max-width: 170px;
    }
}